<template>
  <v-card class="pa-4" flat>
    <v-tabs v-model="tab" center-active @change="tabValue">
      <v-tab>
        <v-icon class="mr-2">mdi-account-check</v-icon>
        {{ $t("master_data.student.tab1_title") }}
      </v-tab>
      <v-tab>
        <v-icon class="mr-2">mdi-account-remove</v-icon>
        {{ $t("routes.teacher") }}
      </v-tab>
      <v-tab>
        <v-icon class="mr-2">mdi-account-remove</v-icon>
        {{ $t("routes.staff") }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" touchless>
      <v-tab-item>
        <Student :init="$route.query.tab == 0" />
      </v-tab-item>
      <v-tab-item>
        <StudentOrEmploye
          :init="$route.query.tab == 1"
          isTeacher
          type="TEACHER"
        />
      </v-tab-item>
      <v-tab-item>
        <StudentOrEmploye
          :init="$route.query.tab == 2"
          isTeacher
          type="STAFF"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import i18n from "@/i18n";
export default {
  metaInfo: {
    title: i18n.t("routes.set_time"),
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  components: {
    Student: () => import("./components"),
    StudentOrEmploye: () => import("./components/StudentOrEmploye")
  },
  data() {
    return {
      tab: this.$route.query.tab | 0
    };
  },
  watch: {
    "$route.query.tab"(n) {
      if (n) this.tab = this.$route.query.tab;
    }
  },
  methods: {
    tabValue(tab) {
      this.$router.push({ name: "SetTime", query: { tab: tab } });
    }
  }
};
</script>
